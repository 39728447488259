<template>
  <div class="telWhatsapp">
    <a class="phone" href="tel:0557 899 400">
      <div class="contact tel"><img src="../assets/Icons/phone.png" alt=""></div>
    </a>
    <a href="https://api.whatsapp.com/send/?phone=996557899400&text&app_absent=0" target="_blank">
      <div class="contact whatsapp"><img src="../assets/Icons/whatsapp.png" alt=""></div>
    </a>
  </div>
</template>

<script>
export default {
  name: "TelWhatsapp"
}
</script>

<style lang="scss" scoped>
.telWhatsapp {
  position: fixed;
  bottom: 25px;
  right: 25px;
  z-index: 100;
  display: flex;
  flex-direction: column;
  grid-gap: 20px;

  .contact {
    max-width: 50px;
    width: 100%;
    height: 50px;
    border-radius: 50%;
    transition: .5s ease-in-out;

    img {
      width: 100%;
    }
  }

  .tel {
    -webkit-box-shadow: 2px 3px 21px -1px rgba(0, 55, 255, 0.92);
    box-shadow: 2px 3px 21px -1px rgba(0, 55, 255, 0.92);
  }

  .whatsapp {
    -webkit-box-shadow: 2px 3px 21px -1px rgba(17, 167, 24, 0.92);
    box-shadow: 2px 3px 21px -1px rgba(17, 167, 24, 0.92);
  }
}
</style>
