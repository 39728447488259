import { render, staticRenderFns } from "./TelWhatsapp.vue?vue&type=template&id=4fbd4264&scoped=true&"
import script from "./TelWhatsapp.vue?vue&type=script&lang=js&"
export * from "./TelWhatsapp.vue?vue&type=script&lang=js&"
import style0 from "./TelWhatsapp.vue?vue&type=style&index=0&id=4fbd4264&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4fbd4264",
  null
  
)

export default component.exports