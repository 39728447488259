import firebase from "firebase/app";
import "firebase/firestore"

const firebaseConfig = {
    apiKey: "AIzaSyCIhL-ohxuQsB1BUkKs58TqifI_aVJq-QQ",
    authDomain: "resmed-2f424.firebaseapp.com",
    projectId: "resmed-2f424",
    storageBucket: "resmed-2f424.appspot.com",
    messagingSenderId: "613709173442",
    appId: "1:613709173442:web:58889ca6fca019931c215b"
};

const firebaseApp = firebase.initializeApp(firebaseConfig)
const timestamp = firebase.firestore.FieldValue.serverTimestamp();
export {timestamp}
export default firebaseApp.firestore()
