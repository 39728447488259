<template>
  <div class="blog-wrapper ">
    <div class="hi">
      <div class="block_content">
        <h1 style="margin-top: 30px">СИПАП аппараты в Бишкеке от прямого поставщика
          по выгодным ценам. <br>
          <span>Эффективное лечение храпа.</span>
        </h1>
        <div class="buttons">
          <a href="tel:0557 899 400">
            <button class="call">Позвонить</button>
          </a>
          <a href="https://api.whatsapp.com/send/?phone=996557899400&text&app_absent=0" target="_blank">
            <button class="text">Написать</button>
          </a>
        </div>
        <h1 style="margin-top: 10px">Доставляем в Россию, страны СНГ</h1>
        <div class="blocks">
          <div class="block_item">
            <div class="img">
              <img src="../assets/Icons/check.png" alt="">
            </div>
            <p>Прозрачность поставок и надёжность сервиса</p>
          </div>
          <div class="block_item">
            <div class="img">
              <img src="../assets/Icons/phone-call.png" alt="">
            </div>
            <p>Первичная настройка и постоянная консультация</p>
          </div>
          <div class="block_item">
            <div class="img">
              <img src="../assets/Icons/settings.png" alt="">
            </div>
            <p>Тех. поддержка купленного аппарата</p>
          </div>
          <div class="block_item">
            <div class="img">
              <img src="../assets/Icons/guaranteed.png" alt="">
            </div>
            <p>Даем гарантию 2 года и дальнейшее обслуживание</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import Arrow from '../assets/Icons/arrow-right-light.svg'

export default {
  name: "blogPost",
  components: {
    // Arrow,
  },
  props: [],
  computed: {
    user() {
      return this.$store.state.user
    }
  },

}
</script>

<style lang="scss" scoped>

.blog-wrapper {
  background-image: url("../assets/blogPhotos/resmed1b.jpeg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  min-height: 600px;
  max-height: 600px;
  @media (min-width: 700px) {
    margin-top: 70px;
  }

  .hi {
    width: 100%;
    min-height: 600px;
    max-height: 600px;
    background-color: rgba(0, 0, 0, 0.47);
    display: flex;
    justify-content: center;
    align-items: center;

    .block_content {
      max-width: 1200px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      h1 {
        color: #fff;
        text-align: center;
        font-size: 18px;
        max-width: 800px;
        @media (min-width: 700px) {
          font-size: 40px;
        }

        span {
          font-size: 10px;
          @media (min-width: 700px) {
            font-size: 20px;
          }
        }
      }

      .buttons {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        grid-gap: 5px;
        @media (min-width: 700px) {
          grid-gap: 20px;
        }

        a {
          text-decoration: none;
        }

        button {
          width: 100px;
          height: 50px;
          border-radius: 5px;
          display: flex;
          justify-content: center;
          align-items: center;
          outline: none;
          border: none;
          font-size: 10px;
          @media (min-width: 700px) {
            width: 200px;
            height: 80px;
            font-size: 20px;
          }
        }

        .call {
          background-color: #1385b3;

          &:hover {
            background-color: #0f6a8f;
          }
        }

        .text {
          background-color: #11a718;

          &:hover {
            background-color: #0c7311;
          }
        }
      }
    }

    .blocks {
      max-width: 1000px;
      width: 100%;
      justify-content: space-around;
      margin-top: 30px;
      display: flex;
      flex-wrap: wrap;
      grid-gap: 10px;

      .block_item {
        color: #fff;
        width: 100px;
        height: 100px;
        background-color: rgba(6, 178, 246, 0.34);
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-direction: column;
        grid-gap: 5px;
        border-radius: 5px;
        border: 1px solid #106dfa;
        cursor: pointer;
        transition: .5s;
        text-align: center;
        font-size: 10px;
        padding: 8px;

        .img {
          max-width: 30px;

          img {
            width: 100%;
          }

          @media (min-width: 700px) {
            max-width: 40px;
          }
        }

        &:hover {
          background-color: rgba(6, 178, 246, 0.51);
          border: 1px solid #10e6fa;
        }

        @media (min-width: 400px) {
          width: 110px;
          height: 110px;
          font-size: 12px;
        }
        @media (min-width: 700px) {
          width: 180px;
          height: 120px;
          font-size: 15px;
        }

      }
    }
  }
}


</style>
