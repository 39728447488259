<template>
  <div class="home">
    <TelWhatsapp/>
    <BlogPost/>
    <div class="blog-card-wrap">
      <div class="container">
        <h3>
          СИПАП и БИПАП аппараты
        </h3>
        <div class="blog-cards">
          <BlogCard @order="order" :post="post" v-for="(post, index) in blogPostsCards" :key="index"/>
        </div>
      </div>
    </div>
    <!--    <div v-if="!user" class="updates">-->
    <!--      <div class="container">-->
    <!--        <ul>-->
    <!--          <li>Прозрачность поставок и надёжность сервиса</li>-->
    <!--          <li>Первичная настройка и постоянная консультация</li>-->
    <!--          <li>Тех. поддержка купленного аппарата</li>-->
    <!--          <li>Даем гарантию 2 года и дальнейшее обслуживание</li>-->
    <!--        </ul>-->
    <!--        <a class="router-button"-->
    <!--           href="https://api.whatsapp.com/send/?phone=996557899400&text&app_absent=0"-->
    <!--           target="_blank">-->
    <!--          Отправить сообщение-->
    <!--          <Arrow class="arrow arrow-light"/>-->
    <!--        </a>-->
    <!--      </div>-->
    <!--    </div>-->
    <div v-if="!user" class="advantage">
      <div class="container">
        <h1>В чем преимущества Сипап аппаратов?</h1>
        <div class="advantage_blocks">
          <div class="block" v-for="(item, index) in advantage" :key="index">
            <h1>{{ item.main_name }}</h1>
            <p>{{ item.description }}</p>
          </div>
        </div>
      </div>
    </div>

    <div v-if="!user" class="video_youtube">
      <div class="container">
        <h1>О СИПАП аппаратах</h1>
        <div class="advantage_blocks">
          <div class="block">
            <iframe
                class="iframe_video"

                src="https://www.youtube.com/embed/5GhXBhB2rC8?rel=0"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
            ></iframe>
          </div>
          <div class="block">
            <iframe
                class="iframe_video"

                src="https://www.youtube.com/embed/hJKoiSxlXII"
                title="YouTube video player" frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen></iframe>
          </div>
          <div class="block">
            <iframe
                class="iframe_video"

                src="https://www.youtube.com/embed/6xSu-1A-fIk?rel=0"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
            ></iframe>
          </div>
        </div>
      </div>
    </div>

    <Map v-if="!user"/>

  </div>
</template>

<script>
import BlogPost from "../components/BlogPost";
import BlogCard from "../components/BlogCard";
import Map from "../components/map";
import TelWhatsapp from "../components/TelWhatsapp";

export default {
  name: "Home",
  components: {
    BlogPost,
    BlogCard,
    Map,
    TelWhatsapp
  },
  data() {
    return {
      welcomeScreen: {
        title: 'RESMED',
        blogPost: 'СИПАП аппараты в Бишкеке - Эффективное лечение храпа в Бишкеке с гарантией результата.',
        welcomeScreen: true,
        photo: 'coding',
      },
      advantage: [
        {
          main_name: 'Универсальность',
          description: 'Отсутствие эффективности других методов лечения.',
        },
        {
          main_name: 'Эффективное лечение',
          description: 'За счет инновационных систем регулирования подачи ' +
              'воздуха, сипап аппараты лечат храп эффективно.',
        },
        {
          main_name: 'Автономность',
          description: 'Не нужно все самому настраивать, наши аппараты сами подстроятся под ваш ритм дыхания.',
        },
        {
          main_name: 'Полная безопасность',
          description: 'Наши сипап аппараты на 100% безопасны для здоровья человека.',
        }
      ],

    };
  },
  methods: {},
  computed: {
    blogPostsFeed() {
      return this.$store.getters.blogPostsFeed
    },
    blogPostsCards() {
      return this.$store.getters.blogPostsCards
    },
    user() {
      return this.$store.state.user
    }
  }
};
</script>

<style lang="scss" scoped>
.modalActive {
  position: fixed;
}

.blog-card-wrap {
  h3 {
    font-weight: 300;
    font-size: 20px;
    margin-bottom: 32px;
    max-width: 300px;
    padding-bottom: 5px;
    border-bottom: 2px solid #dbdbdb;
    transition: 0.5s ease-in-out;

    &:hover {
      border-bottom-color: #1385b3;
    }
  }
}

//.updates {
//  .container {
//    padding: 50px 25px;
//    display: flex;
//    flex-direction: column;
//    align-items: center;
//    @media (min-width: 800px) {
//      padding: 80px 25px;
//      flex-direction: row;
//    }
//
//    ul {
//      li {
//        padding: 10px 0;
//        font-size: 15px;
//        @media (min-width: 800px) {
//          font-size: 20px;
//        }
//      }
//    }
//
//    .router-button {
//      display: flex;
//      font-size: 14px;
//      text-decoration: none;
//      @media (min-width: 800px) {
//        margin-left: auto;
//      }
//    }
//
//    h2 {
//      font-weight: 300;
//      font-size: 32px;
//      max-width: 425px;
//      width: 100%;
//      text-align: center;
//      text-transform: uppercase;
//      @media (min-width: 800px) {
//        text-align: initial;
//        font-size: 40px;
//      }
//    }
//  }
//}

.advantage {
  background-color: #ffffff;
  padding-top: 50px;
  padding-bottom: 50px;
  padding-left: 0;
  padding-right: 40px;

  .container {

    h1 {
      text-align: center;
      font-size: 25px;
      font-weight: 500;
      @media (min-width: 500px) {
        font-size: 30px;
      }
    }

    .advantage_blocks {
      display: flex;
      width: 100%;
      justify-content: center;
      margin: 20px 20px;
      grid-gap: 10px;
      flex-wrap: wrap;

      .block {
        max-width: 350px;
        width: 100%;
        background-color: rgba(10, 160, 224, 0.86);
        padding: 50px 10px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border-radius: 5px;
        transition: .3s ease-in-out;
        color: #fff;

        &:hover {
          transform: rotateZ(-1deg);
          box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.6);
        }

        h1 {
          font-size: 20px;
          margin-bottom: 10px;
        }

        p {
          font-size: 16px;
          text-align: center;
        }
      }
    }
  }
}

.video_youtube {
  background-color: #f1f1f1;
  padding-top: 50px;
  padding-bottom: 50px;
  padding-left: 0;
  padding-right: 40px;

  .container {
    h1 {
      text-align: center;
      font-size: 25px;
      font-weight: 500;
      @media (min-width: 500px) {
        font-size: 30px;
      }
    }

    .advantage_blocks {
      display: flex;
      width: 100%;
      justify-content: space-around;
      margin: 20px 20px;
      grid-gap: 10px;
      flex-wrap: wrap;

      .block {
        max-width: 350px;
        width: 100%;
        padding: 10px 10px;
        border-radius: 5px;
        background-color: #1385b3;
        border: 1px solid #13abe7;
        .iframe_video{
width: 100%;
          height: 100%;
        }
      }
    }
  }
}
</style>
