<template>
  <div class="reset-password">
    <Modal v-if="modalActive" :modalMessage="modalMessage" v-on:close-modal="closeModal"/>
    <Loading v-if="loading"/>
    <div class="form-wrap">
      <form class="reset">

        <p class="login-register">
          войти в
          <router-link class="router-link" :to="{name: 'Login'}"> Админ</router-link>
        </p>

        <h2>Изменить пароль</h2>
        <p class="login-register">Забыли свой пароль? Напишите свою почту</p>
        <div class="inputs">
          <div class="input">
            <input type="text" placeholder="Email" v-model="email">
            <email class="icon"/>
          </div>
        </div>
        <button @click.prevent="resetPassword" class="forgot-password-button">Send</button>
        <div class="angle"></div>
      </form>
      <div class="background"></div>
    </div>
  </div>
</template>

<script>
import email from '../assets/Icons/envelope-regular.svg';
import Modal from '../components/Modal'
import Loading from "../components/Loading";
import firebase from "firebase/app"
import "firebase/auth"

export default {
  name: "ForgotPassword",
  data() {
    return {
      email: "",
      modalActive: false,
      modalMessage: "",
      loading: null,
    }
  },
  components: {
    Loading,
    email,
    Modal
  },
  methods: {
    resetPassword() {
      this.loading = true;
      firebase.auth().sendPasswordResetEmail(this.email).then(() => {
        this.modalMessage = "Если ваш аккаунт существует, вы получите сообщение на почту";
        this.loading = false;
        this.modalActive = true;
      }).catch((err) => {
        this.modalMessage = 'Аккаунт не найден'
        this.loading = false;
        this.modalActive = true;
        console.log(err)
      })
    },
    closeModal() {
      this.modalActive = !this.modalActive
      this.email = '';

    }
  }
}
</script>

<style lang="scss" scoped>
.reset-password {
  position: relative;

  .form-wrap {
    .reset {
      h2 {
        margin-bottom: 8px;
      }

      p {
        text-align: center;
        margin-bottom: 32px;
      }
    }

    //.forgot-password-button{
    //  margin-top: 20px;
    //}
  }
}
</style>
