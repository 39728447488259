import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import Login from "../views/Login";
import ForgotPassword from "../views/ForgotPassword";
import Register from "../views/Register";
import Blogs from "../views/Blogs"
import Profile from "../views/Profile";
import CreatePost from '../views/CreatePost'
import Admin from "../views/Admin";
import BlogPreview from "../views/BlogPreview";
import ViewBlog from "../views/ViewBlog";
import EditBlog from "../views/EditBlog"
import firebase from "firebase/app";

Vue.use(VueRouter);

const routes = [
    {
        path: "/",
        name: "Home",
        component: Home,
        meta: {
            title: 'Home',
            requiresAuth: false,
        }
    },
    {
        path: "/blogs",
        name: "Blogs",
        component: Blogs,
        meta: {
            title: 'Blogs',
            requiresAuth: false,
        }
    },
    {
        path: "/login-admin-part",
        name: "Login",
        component: Login,
        meta: {
            title: "Login",
            requiresAuth: false,
        }
    },
    {
        path: "/register",
        name: "Register",
        component: Register,
        meta: {
            title: "Register",
            requiresAuth: true,
        }
    },
    {
        path: "/forgot-password",
        name: "ForgotPassword",
        component: ForgotPassword,
        meta: {
            title: "ForgotPassword",
            requiresAuth: false,
        }
    },
    {
        path: "/profile",
        name: "Profile",
        component: Profile,
        meta: {
            title: "Profile",
            requiresAuth: true,
        }
    },
    {
        path: "/create-post",
        name: "CreatePost",
        component: CreatePost,
        meta: {
            title: "Create Post",
            requiresAuth: true,
        }
    },
    {
        path: "/admin",
        name: "Admin",
        component: Admin,
        meta: {
            title: "Admin",
            requiresAuth: true,
        }
    },
    {
        path: "/post-preview",
        name: "BlogPreview",
        component: BlogPreview,
        meta: {
            title: "Preview Blog Post",
            requiresAuth: true,
        }
    },
    {
        path: "/view-blog/:blogid",
        name: "ViewBlog",
        component: ViewBlog,
        meta: {
            title: "View Blog Post",
            requiresAuth: false,
        }
    },
    {
        path: "/edit-blog/:blogid",
        name: "EditBlog",
        component: EditBlog,
        meta: {
            title: "EditBlog Post",
            requiresAuth: true,
        }
    },
];

const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    routes,
});

router.beforeEach((to, from, next) => {
    document.title = `${to.meta.title}| RESMED`;
    next();
})
router.beforeEach(async (to, from, next) => {
    let user = firebase.auth().currentUser;
    if (to.matched.some((res) => res.meta.requiresAuth)) {
        if (user) {
            return next()
        }
        return next({name: 'Home'})
    }
    return next();
})
export default router;
