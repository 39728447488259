<template>
  <div class="map">
    <div class="container">
      <h1>наши контакты </h1>
      <div class="map_text">
        <div class="location">
          <iframe
              class="iframe_map"
              src="https://yandex.ru/map-widget/v1/?um=constructor%3A66d946fcd528ac0578427a20980afcc426db2eb98c8148e49f427983906bc70e&amp;source=constructor"
              width="700"
              height="400"
              frameborder="0"
          ></iframe>
        </div>
        <div class="location text_location">
          <h1>Aдрес: г. Бишкек,Чокана Валиханова,проспект Шабдан Баатыра 2/1
            (ориентировочно перес. Чуй/Чолпонатинской)</h1>
          <h1> Тел:
            0557 899 400
          </h1>
          <h1>Электронная почта:
<!--            <spam>info@resmed.kg</spam>-->
            <a href = "mailto: info@resmed.kg">info@resmed.kg</a>
          </h1>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "map"
}
</script>

<style lang="scss" scoped>
.map {
  .container {
    h1 {
      font-size: 20px;
      padding: 20px 0;
      text-transform: uppercase;
      font-weight: 100;
    }

    .map_text {
      width: 100%;
      display: flex;
      grid-gap: 20px;
      flex-direction: column;
      justify-content: center;
      box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.6);
      margin-bottom: 40px;
      justify-content: center;
      align-items: center;
      padding: 20px;
      @media (min-width: 1000px) {
        flex-direction: row;
      }

      .location {
        max-width: 700px;
        width: 100%;
        height: 400px;
        background-color: #0aa0e0;
        border-radius: 5px;
        overflow: hidden;
        border: 1px solid #0aa0e0;
        .iframe_map{
          width: 100%;
          height: 100%;
        }
      }

      .text_location {
        padding: 20px;

        h1 {
          font-weight: initial;
          font-family: sans-serif;
          text-transform: initial;
          font-size: 16px;
          color: #fff;
          a{
            text-decoration: none;
            font-size: 16px;
            font-weight: 550;
            color: #fff;
            @media (min-width: 700px) {
              font-size: 20px;
            }
          }
          @media (min-width: 700px) {
            font-size: 20px;
          }
        }
      }
    }
  }
}
</style>
