<template>
  <div class="blog-card-wrap" >
<!--    <OrderModal v-on:closeModal = "closeModal" class="modalActive" v-show="modalActive"/>-->
    <div class="blog-cards container">
      <div v-if="user" class="toggle-edit">
        <span>Toggle Editing Post</span>
        <input type="checkbox" v-model="editPost">
      </div>
      <BlogCard @order="order" :post="post" v-for="(post, index) in blogPosts" :key="index"/>
    </div>
  </div>
</template>

<script>
import BlogCard from "../components/BlogCard";
// import OrderModal from "../components/OrderModal";

export default {
  name: "blogs",
  data() {
    return {
      modalActive: false,
    }
  },
  components: {
    BlogCard,
    // OrderModal
  },
  methods: {
    order(){
      this.modalActive = !this.modalActive
    },
    closeModal(){
      this.modalActive = !this.modalActive
    }
  },
  computed: {
    user() {
      return this.$store.state.user
    },
    blogPosts() {
      return this.$store.state.blogPosts;
    },
    editPost: {
      get() {
        return this.$store.state.editPost
      },
      set(payload) {
        this.$store.commit("toggleEditPost", payload)
      }
    }
  },
  beforeDestroy() {
    this.$store.commit('toggleEditPost', false)
  }
}
</script>

<style lang="scss" scoped>
.blog-card-wrap {
  .modalActive {
    position: fixed;
    width: 100%;
    left: 0;
    right: 0;
  }

  .blog-cards {
    margin-top: 80px;
    position: relative;

    .toggle-edit {
      display: flex;
      align-items: center;
      position: absolute;
      top: -70px;
      right: 0;

      span {
        margin-right: 16px;
      }

      input[type=checkbox] {
        position: relative;
        border: none;
        -webkit-appearance: none;
        background: #fff;
        outline: none;
        width: 80px;
        height: 30px;
        border-radius: 20px;
        box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
      }

      input[type="checkbox"]:before {
        content: "";
        position: absolute;
        width: 30px;
        height: 30px;
        border-radius: 20px;
        top: 0;
        left: 0;
        background: #303030;
        transform: scale(1.2);
        transition: 750ms ease all;
        box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
      }

      input:checked[type="checkbox"]:before {
        background: #fff;
        left: 52px;
      }
    }
  }
}

</style>
