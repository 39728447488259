<template>
  <div class="post-view" v-if="currentBlog">
    <div class="container quillWrapper">
      <div class="imgName">
        <img :src="this.currentBlog[0].blogCoverPhoto" alt="">
        <div class="imgNameDescription">
          <h2>{{ this.currentBlog[0].blogTitle }}</h2> <br>

        </div>
      </div>
      <div class="post-content ql-editor" v-html="this.currentBlog[0].blogHTML"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ViewBlog",
  data() {
    return {
      currentBlog: null,
    }
  },
  async mounted() {
    this.currentBlog = await this.$store.state.blogPosts.filter(post => {
      return post.blogID === this.$route.params.blogid
    })
  },
}
</script>

<style lang="scss" scoped>
.post-view {
  margin-top: 100px;

  .container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 1000px;
    align-items: center;

    .imgName {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      grid-gap: 10px;
      padding: 15px;
      @media (min-width: 700px) {
        flex-direction: row;
        padding: 0px;
      }
      img {
        max-width: 50%;
      }
      .imgNameDescription {
        h2 {
          font-weight: initial;
          font-size: 30px;
        }

        h4 {
          font-weight: 400;
          font-size: 14px;
          margin-bottom: 24px;
        }
      }

    }

  }

}
</style>

