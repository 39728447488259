<template>
  <div class="form-wrap">
    <form class="register">
      <h2>Создайте Админ аккаунт</h2>
      <div class="inputs">
        <div class="input">
          <input type="text" placeholder="Имя" v-model="firstName">
          <user class="icon"/>
        </div>
        <div class="input">
          <input type="text" placeholder="Фамилия" v-model="lastName">
          <user class="icon"/>
        </div>
        <div class="input">
          <input type="text" placeholder="User Name" v-model="username">
          <user class="icon"/>
        </div>
        <div class="input">
          <input type="text" placeholder="Email" v-model="email">
          <email class="icon"/>
        </div>
        <div class="input">
          <input type="password" placeholder="Пароль" v-model="password">
          <password class="icon"/>
        </div>
        <div v-show="error" class="error">{{ this.errorMsg }}</div>
        <span class="less_password" v-show="lessPassword">пароль должен состоять не менее из 6 символов </span>
      </div>
      <button class="register-button" @click.prevent="register">Sign Up</button>
      <div class="angle"></div>
    </form>
  </div>
</template>

<script>
import email from '../assets/Icons/envelope-regular.svg';
import password from '../assets/Icons/lock-alt-solid.svg';
import user from "../assets/Icons/user-alt-light.svg"
import firebase from "firebase/app"
import "firebase/auth"
import db from "../firebase/firebaseInit"

export default {
  name: "Register",
  components: {
    email,
    password,
    user,
  },
  data() {
    return {
      firstName: "",
      lastName: "",
      username: "",
      email: "",
      password: "",
      error: null,
      errorMsg: "",
      lessPassword: false
    };
  },
  methods: {
    async register() {
      if (this.password.length < 6) {
        this.lessPassword = true
        setTimeout(()=>{
          this.lessPassword = false
        }, 3000)

      }
      if (
          this.email !== "" &&
          this.password !== "" &&
          this.firstName !== "" &&
          this.lastName !== "" &&
          this.username !== ""
      ) {
        this.error = false;
        this.errorMsg = "";
        const firebaseAuth = await firebase.auth();
        const createUser = await firebaseAuth.createUserWithEmailAndPassword(this.email, this.password)
        const result = await createUser;
        const dataBase = db.collection("user").doc(result.user.uid)
        await dataBase.set({
          firstName: this.firstName,
          lastName: this.lastName,
          username: this.username,
          email: this.email,
        });
        this.$router.push({name: "Home"});
        return;
      }
      this.error = true;
      this.errorMsg = "пожалуйста заполните все обязательные поля"

      return;
    },
  }
}
</script>

<style lang="scss" scoped>
.register {
  .less_password{
    color: red;
    font-size: 15px;
  }
  h2 {
    max-width: 350px;
  }

  //.register-button{
  //  margin-top: 20px;
  //}
}
</style>
