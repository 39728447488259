<template>
  <div class="blog-card">
    <!--    <OrderModal v-on:closeModal="closeModal" v-on:gotowhatsapp='gotowhatsapp' class="modalActive" v-show="modalActive">-->
    <!--      <h3>Имя и фамилия</h3><br>-->
    <!--      <input v-model="name" type="text" placeholder="Ваше имя">-->
    <!--      <br>-->
    <!--      <input v-model="lastName" type="text" placeholder="Ваше фамилия"><br>-->
    <!--      <h3>Имя товара</h3>-->
    <!--      <input type="text" :value="productName = post.blogTitle">-->
    <!--    </OrderModal>-->

      <div class="all">
        <div v-show="editPost" class="icons">
          <div @click="editBlog" class="icon">
            <Edit class="edit"/>
          </div>
          <div @click="deletePost" class="icon">
            <Delete class="delete"/>
          </div>
        </div>

        <router-link class="del_ref" :to="{name: 'ViewBlog', params: {blogid: this.post.blogID}}">
          <img :src="post.blogCoverPhoto" alt="">
          <div class="info">
            <h4>{{ post.blogTitle }}</h4>
            <div class="links_Order">
              <p class="link">
                узнать подробнее
                <Arrow class="arrow"/>
              </p>
              <!--          <div class="order" @click="order()">ЗАКАЗАТЬ</div>-->
            </div>
          </div>
        </router-link>

      </div>

  </div>
</template>

<script>
import Arrow from "../assets/Icons/arrow-right-light.svg"
import Edit from "../assets/Icons/edit-regular.svg"
import Delete from "../assets/Icons/trash-regular.svg"
// import OrderModal from "../components/OrderModal";


export default {
  name: "blogCard",
  props: ['post'],
  components: {
    Arrow,
    Edit,
    Delete,
    // OrderModal,
  },
  data() {
    return {
      ActiveModal: null,
      blogModalID: null,
      modalActive: false,
      name: '',
      lastName: '',
      productName: '',
    }
  },
  methods: {
    gotowhatsapp() {
      let url = "https://wa.me/996557899400?text="
          + "Имя:  " + this.name + "%0a"
          + "Телефон:  " + this.lastName + "%0a"
          + "Имя товара:  " + this.productName;

      window.open(url, '_blank').focus();
    },
    // order() {
    //   this.modalActive = !this.modalActive
    // },
    closeModal() {
      this.modalActive = !this.modalActive
    },
    deletePost() {
      this.$store.dispatch("deletePost", this.post.blogID)
    },
    editBlog() {
      this.$router.push({name: 'EditBlog', params: {blogid: this.post.blogID}})
    }
  },
  computed: {
    editPost() {
      return this.$store.state.editPost
    },

  }
}
</script>

<style lang="scss" scoped>

.blog-card {
  max-height: 450px;
  //.modalActive{
  //  .productNameWhatsapp{
  //    padding: 10px 5px;
  //    background-color: rgb(107, 106, 106);
  //    color: #ffffff;
  //    margin-top: 5px;
  //    word-break: break-word;
  //    border-radius: 5px;
  //  }
  //}


    .all {
      position: relative;
      cursor: pointer;
      transition: .5s ease all;
      border: .1px solid #1385b3;
      border-radius: 8px;
      background-color: #0aa0e0;
      height: 100%;
      &:hover {
        transform: rotateZ(-1deg) scale(1.01);
        box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.6);
      }

      .icons {
        display: flex;
        position: absolute;
        top: 10px;
        right: 10px;
        z-index: 99;

        .icon {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 35px;
          height: 35px;
          border-radius: 50%;
          background-color: #fff;
          transition: .5s ease all;

          &:hover {
            background-color: #303030;

            .edit,
            .delete {
              path {
                fill: #fff
              }
            }
          }

          &:nth-child(1) {
            margin-right: 8px;
          }

          .edit,
          .delete {
            pointer-events: none;
            height: 15px;
            width: auto;
          }
        }
      }

      .del_ref{
        text-decoration: none;
        img {
          display: block;
          border-radius: 8px 8px 0 0;
          z-index: 1;
          width: 100%;
          max-height: 50%;
          object-fit: cover;
        }

        .info {
          display: flex;
          flex-direction: column;
          height: 50%;
          z-index: 3;
          padding: 32px 16px;
          color: #000;
          justify-content: space-between;

          .links_Order {
            display: flex;
            justify-content: space-between;

            .order {
              background-color: #15b51d;
              color: #fff;
              padding: 4px;
              border-radius: 3px;
              transition: 0.5s ease-in-out;

              &:hover {
                background-color: #11a718;
                color: #e9e8e8;
              }
            }

            .link {
              display: inline-flex;
              align-items: center;
              margin-top: auto;
              font-weight: 500;
              font-size: 12px;
              padding-bottom: 4px;
              transition: .5s ease-in all;
              color: #fff;

              .arrow {
                width: 12px;

                path {
                  transition: .5s ease-in all;
                  fill: #fff;
                }
              }

              &:hover {
                color: rgba(229, 228, 228, 0.8);

                .arrow {
                  path {
                    fill: rgba(229, 228, 228, 0.8);
                  }
                }
              }


            }
          }


          h4 {
            padding-bottom: 8px;
            font-size: 15px;
            font-weight: 400;
            color: #ffffff;
            display: block;
            width: 100%;
            overflow: no-content;
            word-break: break-word;

            @media (min-width: 700px) {
              font-size: 20px;
            }
          }

          h6 {
            font-weight: 400;
            font-size: 15px;
            padding-bottom: 16px;
            color: #fff;
          }
        }
      }
    }



}


</style>
